import { ContractModel } from 'ngx-q360-lib';

export class ContractUtils {
  static get contractTypes(): ContractModel[] {
    return [
      // client - consultant
      {
        id: 'NS 8401',
        name: 'NS 8401 - General conditions of contract for design commisions',
        nameNo: 'NS 8401 - Alminnelige kontraktsbestemmelser for prosjekteringsoppdrag',
        partner1: 'Client',
        partner2: 'Consultant',
      },
      {
        id: 'NS 8402',
        name: 'NS 8402 - General conditions of contract for consultancy commissions with remuneration on the basis of actual time taken',
        nameNo: 'NS 8402 - Alminnelige kontraktsbestemmelser for rådgivningsoppdrag honorert etter medgått tid',
        partner1: 'Client',
        partner2: 'Consultant',
      },
      {
        id: 'NS 8403',
        name: 'NS 8403 - General conditions of contract for construction supervision commissions.',
        nameNo: 'NS 8403 - Alminnelige kontraktsbestemmelser for byggelederoppdrag',
        partner1: 'Client',
        partner2: 'Consultant',
      },
      {
        id: 'NS 8404',
        name: 'NS 8404 - N/A',
        nameNo: 'NS 8404 - Alminnelige kontraktsbestemmelser for uavhengige kontrolloppdrag',
        partner1: 'Client',
        partner2: 'Consultant',
      },

      // client - sub-consultant
      {
        id: 'NS 8401',
        name: 'NS 8401 - General conditions of contract for design commisions',
        nameNo: 'NS 8401 - Alminnelige kontraktsbestemmelser for prosjekteringsoppdrag',
        partner1: 'Client',
        partner2: 'SubConsultant',
      },
      {
        id: 'NS 8402',
        name: 'NS 8402 - General conditions of contract for consultancy commissions with remuneration on the basis of actual time taken',
        nameNo: 'NS 8402 - Alminnelige kontraktsbestemmelser for rådgivningsoppdrag honorert etter medgått tid',
        partner1: 'Client',
        partner2: 'SubConsultant',
      },
      {
        id: 'NS 8403',
        name: 'NS 8403 - General conditions of contract for construction supervision commissions.',
        nameNo: 'NS 8403 - Alminnelige kontraktsbestemmelser for byggelederoppdrag',
        partner1: 'Client',
        partner2: 'SubConsultant',
      },
      {
        id: 'NS 8404',
        name: 'NS 8404 - N/A',
        nameNo: 'NS 8404 - Alminnelige kontraktsbestemmelser for uavhengige kontrolloppdrag',
        partner1: 'Client',
        partner2: 'SubConsultant',
      },

      // client - sub-client
      {
        id: 'NS 8403',
        name: 'NS 8403 - General conditions of contract for construction supervision commissions',
        nameNo: 'NS 8403 - Alminnelige kontraktsbestemmelser for byggelederoppdrag',
        partner1: 'Client',
        partner2: 'SubClient',
      },
      {
        id: 'NS 8404',
        name: 'NS 8404 - N/A',
        nameNo: 'NS 8404 - Alminnelige kontraktsbestemmelser for uavhengige kontrolloppdrag',
        partner1: 'Client',
        partner2: 'SubClient',
      },

      // client - constructor
      {
        id: 'NS 8405',
        name: 'NS 8405 - Norwegian building and civil engineering contract',
        nameNo: 'NS 8405 - Norsk bygge- og anleggskontrakt',
        partner1: 'Client',
        partner2: 'Constructor',
      },
      {
        id: 'NS 8406',
        name: 'NS 8406 - Simplified Norwegian building and civil engineering contract',
        nameNo: 'NS 8406 - Forenklet norsk bygge- og anleggskontrakt',
        partner1: 'Client',
        partner2: 'Constructor',
      },
      {
        id: 'NS 8407',
        name: 'NS 8407 - General conditions of contract for design and build contracts',
        nameNo: 'NS 8407 - Alminnelige kontraktsbestemmelser for totalentrepriser',
        partner1: 'Client',
        partner2: 'Constructor',
      },

      // client - sub-constructor
      {
        id: 'NS 8405',
        name: 'NS 8405 - Norwegian building and civil engineering contract',
        nameNo: 'NS 8405 - Norsk bygge- og anleggskontrakt',
        partner1: 'Client',
        partner2: 'SubConstructor',
      },
      {
        id: 'NS 8406',
        name: 'NS 8406 - Simplified Norwegian building and civil engineering contract',
        nameNo: 'NS 8406 - Forenklet norsk bygge- og anleggskontrakt',
        partner1: 'Client',
        partner2: 'SubConstructor',
      },
      {
        id: 'NS 8407',
        name: 'NS 8407 - General conditions of contract for design and build contracts',
        nameNo: 'NS 8407 - Alminnelige kontraktsbestemmelser for totalentrepriser',
        partner1: 'Client',
        partner2: 'SubConstructor',
      },

      // consultant - sub-consultant, constructor - sub-consultant
      {
        id: 'NS 8401',
        name: 'NS 8401 - General conditions of contract for design commisions',
        nameNo: 'NS 8401 - Alminnelige kontraktsbestemmelser for prosjekteringsoppdrag',
        partner1: 'Consultant',
        partner2: 'SubConsultant',
      },
      {
        id: 'NS 8402',
        name: 'NS 8402 - General conditions of contract for consultancy commissions with remuneration on the basis of actual time taken',
        nameNo: 'NS 8402 - Alminnelige kontraktsbestemmelser for rådgivningsoppdrag honorert etter medgått tid',
        partner1: 'Consultant',
        partner2: 'SubConsultant',
      },
      {
        id: 'NS 8401',
        name: 'NS 8401 - General conditions of contract for design commisions',
        nameNo: 'NS 8401 - Alminnelige kontraktsbestemmelser for prosjekteringsoppdrag',
        partner1: 'Constructor',
        partner2: 'SubConsultant',
      },
      {
        id: 'NS 8402',
        name: 'NS 8402 - General conditions of contract for consultancy commissions with remuneration on the basis of actual time taken',
        nameNo: 'NS 8402 - Alminnelige kontraktsbestemmelser for rådgivningsoppdrag honorert etter medgått tid',
        partner1: 'Constructor',
        partner2: 'SubConsultant',
      },

      // constructor - sub-constructor, consultant - sub-constructor
      {
        id: 'NS 8415',
        name: 'NS 8415 - Norwegian contract for sub-contracts concerning the execution of building and civil engineering works',
        nameNo: 'NS 8415 - Norsk underentreprisekontrakt vedrørende utførelse av bygge- og anleggsarbeider',
        partner1: 'Constructor',
        partner2: 'SubConstructor',
      },
      {
        id: 'NS 8416',
        name: 'NS 8416 - Simplified Norwegian contract for sub-contracts concerning the execution of building and civil engineering works',
        nameNo: 'NS 8416 - Forenklet norsk underentreprisekontrakt vedrørende utførelse av bygge- og anleggsarbeider',
        partner1: 'Constructor',
        partner2: 'SubConstructor',
      },
      {
        id: 'NS 8417',
        name: 'NS 8417 - General conditions of contract for design and build sub-contracts',
        nameNo: 'NS 8417 - Alminnelige kontraktsbestemmelser for totalunderentrepriser',
        partner1: 'Constructor',
        partner2: 'SubConstructor',
      },
      {
        id: 'NS 8415',
        name: 'NS 8415 - Norwegian contract for sub-contracts concerning the execution of building and civil engineering works',
        nameNo: 'NS 8415 - Norsk underentreprisekontrakt vedrørende utførelse av bygge- og anleggsarbeider',
        partner1: 'Consultant',
        partner2: 'SubConstructor',
      },
      {
        id: 'NS 8416',
        name: 'NS 8416 - Simplified Norwegian contract for sub-contracts concerning the execution of building and civil engineering works',
        nameNo: 'NS 8416 - Forenklet norsk underentreprisekontrakt vedrørende utførelse av bygge- og anleggsarbeider',
        partner1: 'Consultant',
        partner2: 'SubConstructor',
      },
      {
        id: 'NS 8417',
        name: 'NS 8417 - General conditions of contract for design and build sub-contracts',
        nameNo: 'NS 8417 - Alminnelige kontraktsbestemmelser for totalunderentrepriser',
        partner1: 'Consultant',
        partner2: 'SubConstructor',
      },

      // consultant - constructor
      {
        id: 'NS 8405',
        name: 'NS 8405 - Norwegian building and civil engineering contract',
        nameNo: 'NS 8405 - Norsk bygge- og anleggskontrakt',
        partner1: 'Consultant',
        partner2: 'Constructor',
      },
      {
        id: 'NS 8406',
        name: 'NS 8406 - Simplified Norwegian building and civil engineering contract',
        nameNo: 'NS 8406 - Forenklet norsk bygge- og anleggskontrakt',
        partner1: 'Consultant',
        partner2: 'Constructor',
      },
      {
        id: 'NS 8407',
        name: 'NS 8407 - General conditions of contract for design and build contracts',
        nameNo: 'NS 8407 - Alminnelige kontraktsbestemmelser for totalentrepriser',
        partner1: 'Consultant',
        partner2: 'Constructor',
      },

      // new rules
      // consultant - consultant
      {
        id: 'NS 8401',
        name: 'NS 8401 - General conditions of contract for design commisions',
        nameNo: 'NS 8401 - Alminnelige kontraktsbestemmelser for prosjekteringsoppdrag',
        partner1: 'Consultant',
        partner2: 'Consultant',
        isEqual: true,
      },
      {
        id: 'NS 8402',
        name: 'NS 8402 - General conditions of contract for consultancy commissions with remuneration on the basis of actual time taken',
        nameNo: 'NS 8402 - Alminnelige kontraktsbestemmelser for rådgivningsoppdrag honorert etter medgått tid',
        partner1: 'Consultant',
        partner2: 'Consultant',
        isEqual: true,
      },
      {
        id: 'NS 8403',
        name: 'NS 8403 - General conditions of contract for construction supervision commissions.',
        nameNo: 'NS 8403 - Alminnelige kontraktsbestemmelser for byggelederoppdrag',
        partner1: 'Consultant',
        partner2: 'Consultant',
        isEqual: true,
      },
      {
        id: 'NS 8404',
        name: 'NS 8404 - N/A',
        nameNo: 'NS 8404 - Alminnelige kontraktsbestemmelser for uavhengige kontrolloppdrag',
        partner1: 'Consultant',
        partner2: 'Consultant',
        isEqual: true,
      },

      // constructor - constructor
      {
        id: 'NS 8405',
        name: 'NS 8405 - Norwegian building and civil engineering contract',
        nameNo: 'NS 8405 - Norsk bygge- og anleggskontrakt',
        partner1: 'Constructor',
        partner2: 'Constructor',
        isEqual: true,
      },
      {
        id: 'NS 8406',
        name: 'NS 8406 - Simplified Norwegian building and civil engineering contract',
        nameNo: 'NS 8406 - Forenklet norsk bygge- og anleggskontrakt',
        partner1: 'Constructor',
        partner2: 'Constructor',
        isEqual: true,
      },
      {
        id: 'NS 8407',
        name: 'NS 8407 - General conditions of contract for design and build contracts',
        nameNo: 'NS 8407 - Alminnelige kontraktsbestemmelser for totalentrepriser',
        partner1: 'Constructor',
        partner2: 'Constructor',
        isEqual: true,
      },
    ] as ContractModel[];
  }

  static getContractTypesByPartners(partner1: string, partner2: string, language = 'en'): ContractModel[] {
    const isEqual = partner1 === partner2 || undefined;
    const result = this.contractTypes.filter(
      (el) =>
        (el.partner1 === partner1 || el.partner1 === partner2) &&
        (el.partner2 === partner1 || el.partner2 === partner2) &&
        el.isEqual === isEqual,
    );
    if (language !== 'en') {
      return result.map((ct) => ({ ...ct, name: ct.nameNo }));
    }

    result.push({
      id: 'unknown',
      name: 'Contract conditions not listed',
    } as ContractModel);

    return result;
  }
}
